import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import {
  ChampionsCard,
  Layout,
  Title,
  Card,
  CardContainer,
  EducateContent,
  Button,
  ChampionsCardNew,
  CardNew,
  ChampionsNewCard,
  ButtonDiv,
} from "./styles";
import { NoComments } from "../SocialFeeds/styles";
import { CircleContainer, Circle, Tick } from "../Journeys/styles";
import {
  SplitEngagementSection,
  EngagementContainer,
} from "../EngagementSection/styles";
import Thumbnail from "../Thumbnail";
import ThumbnailNew from "../ThumbnailNew";
import { ImageUrl, ImageUrlNew } from "../../utils/constants";
import { withTranslation } from "react-i18next";
import { AdminButtons } from "../Article/styles";
import AdventurePointDashBoard from "../AdventurePointDashBoard"
import ArticleCard from "./recommendedArticles";

const iconPath = {
  svg: "M8,3.44c-4.32,-7.48 -15.61,3.63 0,12.56c15.61,-8.93 4.32,-20.03 0,-12.56z",
  fill: "#ef6827",
};

class AdventureCategories extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      temp: 0,
      tempNew: 0,
      save: 0,
    };
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1,
    });
  };

  seeMore = () => {
    this.setState({
      temp: !this.state.temp,
      tempNew: !this.state.tempNew,
    });
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    });
  };

  changeEducationDashboard = (name) => {
    const { history } = this.props;
    history.push(`/education/adventure/${name.toLowerCase()}`);
  };
  getEducationContainer = (adventuresDetails) => (
    <div>
      <div
        style={{
          display: "flex",
          "flex-wrap": "wrap",
          background: "white",
          padding: "2px 2px 0px 2px",
          "border-radius": "6px",
        }}
      >
        {adventuresDetails.map((data, index) =>
          index < 10 ? (
            <ChampionsCard key={index} grid={5}>
              <Card
                onClick={() =>
                  this.props.history.push(
                    `/education/adventure/${data[
                      "category_name"
                    ].toLowerCase()}`
                  )
                }
                adventurePage
                border={index === 0 || index % 5 === 0 ? 1 : 0}
              >
                <div
                  className="video-category"
                  onMouseEnter={() => this.onEnterMouse(data.id)}
                  onMouseLeave={() => this.onLeaveMouse()}
                >
                  <ThumbnailNew
                    src={`${ImageUrl}/${data.image}`}
                    alt={data.category_name}
                  />
                </div>
                <div className="text">{this.props.t(data.category_name)}</div>
              </Card>
            </ChampionsCard>
          ) : null
        )}
        {adventuresDetails.map((data, index) =>
          index >= 10 && this.state.temp ? (
            <ChampionsCard key={index} grid={5} width={"calc((100% - 25px) / 2)"}>
              <Card
                adventurePage
                onClick={() =>
                  this.props.history.push(
                    `/education/adventure/${data[
                      "category_name"
                    ].toLowerCase()}`
                  )
                }
                border={index === 0 || index % 5 === 0 ? 1 : 0}
              >
                <div
                  className="video-category"
                  onMouseEnter={() => this.onEnterMouse(data.id)}
                  onMouseLeave={() => this.onLeaveMouse()}
                >
                  <ThumbnailNew
                    src={`${ImageUrl}/${data.image}`}
                    alt={data.category_name}
                  />
                </div>
                <div className="text">{this.props.t(data.category_name)}</div>
              </Card>
            </ChampionsCard>
          ) : null
        )}
      </div>
      <ButtonDiv>
        {this.state.temp ? (
          <Button onClick={() => this.seeMore()}>
            {this.props.t("See Less")}{" "}
            <img
              src="/public/images/NewDashboardV2/downarrownew.png"
              style={{ transform: "rotate(180deg)" }}
            />
          </Button>
        ) : (
          <Button onClick={() => this.seeMore()}>
            {this.props.t("See More")}{" "}
            <img src="/public/images/NewDashboardV2/downarrownew.png" />
          </Button>
        )}
      </ButtonDiv>
    </div>
  );

  showArticle = (adventure) => {
    const { history } = this.props;
    if (adventure.lock_status === 1) {
      history.push(
        `/education/adventure/${adventure.category_name.toLowerCase()}/article/${
          adventure.id
        }`
      );
    } else {
      toast.info(
        "This article is locked, please read the previous articles in the same category to unlock"
      );
    }
  };

  getQuizForArticle = (data) => {
    const { history } = this.props;
    history.push(
      `/education/adventure/${data.category_name.toLowerCase()}/article/${
        data.id
      }/quiz`
    );
  };

  getRecommendedAdventureDashboard = (adventuresDetails, onHover) =>
    adventuresDetails.map((data, index) => (
      <ChampionsCardNew key={index}>
        {this.state.save !== 0 ? (
          <img
            src="/public/images/NewDashboardV2/ribbonColor.png"
            onClick={() => this.saveImage()}
          />
        ) : (
          <img
            src="/public/images/NewDashboardV2/ribbon.png"
            onClick={() => this.saveImage()}
          />
        )}
        <CardNew recommended={1}>
          <div
            onMouseEnter={() => this.onEnterMouse(data.id)}
            onMouseLeave={() => this.onLeaveMouse()}
          >
            <Thumbnail
              src={`${ImageUrlNew}/${data.image}`}
              alt={data.category_name}
              onClick={() => this.showArticle(data)}
            />
            {onHover === data.id && (
              <div className="educateImageBackground">
                <div
                  className="getStarted"
                  onClick={() =>
                    this.changeEducationDashboard(data.category_name)
                  }
                >
                  {this.props.t("Start Learning")}
                </div>
              </div>
            )}
          </div>
          <div className="content">
            <EducateContent>
              <div className="workEducation">
                <div className="workName">
                  <span onClick={() => this.showArticle(data)}>
                    {data.title}
                  </span>
                </div>
              </div>
              <div className="workEducationCheck">
                <CircleContainer marginRight={1}>
                  <div className="flex">
                    <Circle
                      borderColor="#9c9c9c"
                      checked={data.quiz_status}
                      onClick={() => this.showArticle(data)}
                    >
                      <Tick checked={data.read_status} />
                    </Circle>
                  </div>
                  <div
                    className="quiz"
                    style={{ "text-transform": "capitalize" }}
                  >
                    Article
                  </div>
                </CircleContainer>
                {data.quiz_available && (
                  <CircleContainer>
                    <div className="flex">
                      <Circle
                        borderColor="#9c9c9c"
                        checked={data.quiz_status}
                        onClick={() => this.getQuizForArticle(data)}
                      >
                        <Tick checked={data.quiz_status} />
                      </Circle>
                    </div>
                    <div
                      className="quiz"
                      style={{ "text-transform": "capitalize" }}
                    >
                      Quiz
                    </div>
                  </CircleContainer>
                )}
              </div>
            </EducateContent>
          </div>
        </CardNew>
      </ChampionsCardNew>
    ));

  getRecommendedAdventureDashboardNew = (adventuresDetails, onHover, history) =>
    adventuresDetails.map((data, index) => (
      <ChampionsNewCard key={index} margin={index % 2 === 1 ? "0px" : "25px"}>
        {this.state.save !== 0 ? (
          <img
            src="/public/images/NewDashboardV2/ribbonColor.png"
            onClick={() => this.saveImage()}
          />
        ) : (
          <img
            src="/public/images/NewDashboardV2/ribbon.png"
            onClick={() => this.saveImage()}
          />
        )}
        <CardNew recommended={1}>
          <div
            onMouseEnter={() => this.onEnterMouse(data.id)}
            onMouseLeave={() => this.onLeaveMouse()}
          >
            <Thumbnail
              src={`${ImageUrlNew}/${data.image}`}
              alt={data.category_name}
              onClick={() => this.showArticle(data)}
            />
            {onHover === data.id && (
              <div
                className="educateImageBackground"
                onClick={() => history.push("/education/create-category")}
              >
                <div
                  className="getStarted"
                  onClick={() =>
                    this.changeEducationDashboard(data.category_name)
                  }
                >
                  Start Learning
                </div>
              </div>
            )}
          </div>
          <div className="content">
            <EducateContent>
              <div className="workEducation">
                <div className="workName">
                  <span onClick={() => this.showArticle(data)}>
                    {data.title}
                  </span>
                </div>
              </div>
              <div className="workEducationCheck">
                <CircleContainer marginRight={1}>
                  <div className="flex">
                    <Circle
                      borderColor="#9c9c9c"
                      onClick={() => this.showArticle(data)}
                    >
                      <Tick checked={data.read_status} />
                    </Circle>
                  </div>
                  <div className="quiz">Article</div>
                </CircleContainer>
                {data.quiz_available && (
                  <CircleContainer>
                    <div className="flex">
                      <Circle
                        borderColor="#9c9c9c"
                        onClick={() => this.getQuizForArticle(data)}
                      >
                        <Tick checked={data.quiz_status} />
                      </Circle>
                    </div>
                    <div className="quiz">Quiz</div>
                  </CircleContainer>
                )}
              </div>
            </EducateContent>
          </div>
        </CardNew>
      </ChampionsNewCard>
    ));

  render() {
    const {
      adventureCategories,
      featuredJourney,
      history,
      favoriteArticles,
      onClickFavoriteRibbon,
      createCategory,
      t,
      createArticle,
    } = this.props;
    const { onHover } = this.state;
    let featuredJourneyData =
      featuredJourney &&
      featuredJourney.length > 0 &&
      featuredJourney.slice(0, 4);
    return (
      <Layout>
        <div>
          <EngagementContainer>
            <SplitEngagementSection gridWidth="67.1%" smallWidth="100%">
              <CardContainer>
                {isEmpty(adventureCategories) ? (
                  <NoComments>{t("No Articles Found")}</NoComments>
                ) : (
                  this.getEducationContainer(
                    adventureCategories,
                    iconPath,
                    onHover
                  )
                )}
              </CardContainer>

              <Title paddingbottom={"25px"}>{t("Featured Articles")}</Title>
              <CardContainer style={{ marginRight: "0px" }}>
                {isEmpty(featuredJourneyData) ? (
                  <NoComments>{t("No Recommended Articles")}</NoComments>
                ) : (
                  featuredJourneyData && featuredJourneyData?.map((data, index) => (
                    <ArticleCard
                      onClickFavoriteRibbon={onClickFavoriteRibbon}
                      data={data}
                      key={index}
                      history={history}
                      margin={index === 1 ? "0px" : "25px"}
                      width={"calc((100% - 25px) / 2)"}
                      bottom={"25px"}
                      showArticle={this.showArticle}
                    />
                  ))
                )}
              </CardContainer>
              {favoriteArticles?.length>0&&<Title padding={"15px"}>{t("Favorite Articles")}</Title>}
              <CardContainer style={{ marginRight: "0px" }}>
                {isEmpty(favoriteArticles) && favoriteArticles.length > 0 ? (
                  <NoComments>{t("No Favorite Articles")}</NoComments>
                ) : (
                  favoriteArticles && favoriteArticles?.map((data, index) => (
                    <ArticleCard
                      onClickFavoriteRibbon={onClickFavoriteRibbon}
                      data={data}
                      key={index}
                      history={history}
                      margin={index === 1 ? "0px" : "25px"}
                      width={"calc((100% - 25px) / 2)"}
                      bottom={"25px"}
                      showArticle={this.showArticle}
                    />
                  ))
                )}
              </CardContainer>
            </SplitEngagementSection>
            <SplitEngagementSection
              PaddingRight={1}
              smallPadding="0px 10px"
              smallWidth="100%"
              style={{ position: "relative", bottom: "60px" }}
            >
              {createArticle && (
                <AdminButtons
                  background={"#9FC989"}
                  onClick={() => history.push("/education/create-adventure")}
                >
                  <div>
                    <img
                      src="/public/images/NewDashboardV2/editarticle.png"
                      alt="back-arrow"
                    />
                  </div>
                  <div>{t("Add An Article")}</div>
                </AdminButtons>
              )}
              {createCategory && (
                <AdminButtons
                  background={"#69C2FF"}
                  onClick={() => history.push({pathname:"/education/create-category",state:{categoryKey:"ARTICLE"}})}
                >
                  <div>
                    <img
                      src="/public/images/NewDashboardV2/createjourney.png"
                      alt="back-arrow"
                    />
                  </div>
                  <div>{t("Create Article Category")}</div>
                </AdminButtons>
              )}
              <AdventurePointDashBoard />
            </SplitEngagementSection>
          </EngagementContainer>
        </div>
      </Layout>
    );
  }
}

AdventureCategories.propTypes = {
  adventureCategories: PropTypes.array.isRequired,
  featuredJourney: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  createArticle: PropTypes.bool,
  createCategory: PropTypes.bool,
  t: PropTypes.func,
  onClickFavoriteRibbon: PropTypes.func,
  favoriteArticles: PropTypes.array,
};

export default withTranslation()(AdventureCategories);